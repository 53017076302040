.container {
  align-items: stretch;
  background: #272726;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;  
  width: 100%;
}

.header {
  background: #424242;
  flex-shrink: 0;
  flex-grow: 0;
  height: 54px;
}

.control {  
  color: rgba(255, 255, 255, .7);
  height: calc(100% - 108px);
}

.control > div { 
  height: 100%;
}

.footer {
  background: #424242;
  flex-shrink: 0;
  flex-grow: 0;
  height: 54px;
}

.image {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}


.grid {
  display: block;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.selection {
  background:rgba(255, 20, 25, .5);
  opacity: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;  
}
