.container {
  height: 100%;
  width: 100%; 
}

.image-container {
  display: flex;
  height: 100%;  
  object-fit: contain;
  object-position: center;
  position: relative;
  width: 100%;
}

.image {
}

.grid {
  background-image: linear-gradient(currentColor 1px, transparent 1px),linear-gradient(to right, currentColor 1px, transparent 1px);
  background-size: 25px 25px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

